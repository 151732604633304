// Carousel.js
import { useCallback, useState } from 'react';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import annotate_png from '../assets/landing/carouselIcons/noun-annotate-6543339.png';
import annotate_webp from '../assets/landing/carouselIcons/noun-annotate-6543339.webp';
import emotion_png from '../assets/landing/carouselIcons/noun-emotion-6302414.png';
import emotion_webp from '../assets/landing/carouselIcons/noun-emotion-6302414.webp';
import export_png from '../assets/landing/carouselIcons/noun-export-7123930.png';
import export_webp from '../assets/landing/carouselIcons/noun-export-7123930.webp';
import graph_png from '../assets/landing/carouselIcons/noun-graph-7503626.png';
import graph_webp from '../assets/landing/carouselIcons/noun-graph-7503626.webp';
import insights_png from '../assets/landing/carouselIcons/noun-insights-4407299.png';
import insights_webp from '../assets/landing/carouselIcons/noun-insights-4407299.webp';
import map_png from '../assets/landing/carouselIcons/noun-map-7523910.png';
import map_webp from '../assets/landing/carouselIcons/noun-map-7523910.webp';
import play_png from '../assets/landing/carouselIcons/noun-play-2729465.png';
import play_webp from '../assets/landing/carouselIcons/noun-play-2729465.webp';
import project_png from '../assets/landing/carouselIcons/noun-project-7511154.png';
import project_webp from '../assets/landing/carouselIcons/noun-project-7511154.webp';
import quality_png from '../assets/landing/carouselIcons/noun-quality-1778378.png';
import quality_webp from '../assets/landing/carouselIcons/noun-quality-1778378.webp';
import sentiment_png from '../assets/landing/carouselIcons/noun-sentiment-analysis-7510760.png';
import sentiment_webp from '../assets/landing/carouselIcons/noun-sentiment-analysis-7510760.webp';
import '../styles/Carousel.css';

type CarouselItem = {
  id: number;
  title: string;
  text: string;
  png: string;
  webp: string;
};

export const Carousel = () => {
  const items: CarouselItem[] = [
    {
      id: 1,
      title: 'Video Playback',
      text: 'Watch your videos back in real time for cross comparison.',
      png: play_png,
      webp: play_webp,
    },
    {
      id: 2,
      title: 'Automatic Insights',
      text: 'Insights containing interesting points and events found in your video.',
      png: insights_png,
      webp: insights_webp,
    },
    {
      id: 3,
      title: 'Video Quality',
      text: 'Analyse the quality of your videos to determine accuracy of outputs.',
      png: quality_png,
      webp: quality_webp,
    },
    {
      id: 4,
      title: 'Emotional Recognition',
      text: 'Derive metrics about the emotional states and changes.',
      png: emotion_png,
      webp: emotion_webp,
    },
    {
      id: 5,
      title: 'Data Export',
      text: 'Export your video processed information to make data driven decisions.',
      png: export_png,
      webp: export_webp,
    },
    {
      id: 6,
      title: 'Sentiment Analysis',
      text: "Understand the 'why' behind changes to emotional states.",
      png: sentiment_png,
      webp: sentiment_webp,
    },
    {
      id: 7,
      title: 'Emotion Mapping',
      text: 'Follow the flow of emotions presented through your videos.',
      png: map_png,
      webp: map_webp,
    },
    {
      id: 8,
      title: 'Manual Annotation',
      text: 'Add custom insights alongside your videos. Marking events for comparison.',
      png: annotate_png,
      webp: annotate_webp,
    },
    {
      id: 9,
      title: 'Project Analysis',
      text: 'Gain insights to all videos within a project. Summarising all your data in one location.',
      png: project_png,
      webp: project_webp,
    },
    {
      id: 10,
      title: 'Project Analysis',
      text: 'Access the numerical data and see trends outputted from your video by frame.',
      png: graph_png,
      webp: graph_webp,
    },
    // Add more items as needed
  ];

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  return (
    <div id="carouselDiv">
      <HiOutlineChevronLeft
        style={{ fontSize: '30px' }}
        onClick={handlePrevious}
      />
      <Swiper
        // spaceBetween={10} // Space between slides
        onSwiper={setSwiperRef}
        slidesPerView={4} // Default for larger screens
        // centeredSlides={true} // Center slides
        // centerInsufficientSlides={true}
        // breakpoints={{
        //   640: { slidesPerView: 1 }, // 1 item for smaller screens
        //   768: { slidesPerView: 2 }, // 2 items for tablets
        //   1024: { slidesPerView: 2 }, // 2 items for medium screens
        //   1280: { slidesPerView: 3 }, // 3 items for large screens
        //   1536: { slidesPerView: 4 }, // 4 items for extra-large screens
        //   1920: { slidesPerView: 5 }, // 5 items for very large screens
        //   2560: { slidesPerView: 6 }, // 6 items for very large screens
        // }}
        initialSlide={Math.floor(items.length / 2)} // Start at the middle slide
        style={{ width: '100%', overflow: 'hidden' }} // Full width and hide overflow
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <ClickableCard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <HiOutlineChevronRight
        style={{ fontSize: '30px' }}
        onClick={handleNext}
      />
    </div>
  );
};

const ClickableCard = ({ item }: { item: CarouselItem }) => {
  return (
    <div className="clickableCard">
      <div className="cardHeader">
        <img
          src={item.webp}
          alt={item.title}
          className="cardImage"
          onError={(e) => {
            e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
            e.currentTarget.src = item.png; // Fallback to the original JPG or PNG
          }}
        />

        <p className="cardTitle boldText">{item.title}</p>
      </div>
      <p className="cardText">{item.text}</p>
    </div>
  );
};
