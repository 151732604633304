// app/routes/home.tsx
import {
  HeadersFunction,
  json,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { ReactFlowProvider } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { createRef, useEffect, useState } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { IoArrowUpOutline } from 'react-icons/io5';
import { ArrowTimeline } from '~/components/ArrowTimeline';
import { BackgroundGradient } from '~/components/BackgroundGradient';
import { Carousel } from '~/components/Carousel';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { InteractiveDemo } from '~/components/InteractiveDemo';
import { pricing, useCases } from '~/constants';
import '~/styles/home.css';
import deviceImage from '../assets/herosection.png';
import introVideo from '../assets/landing/face-detection-futuristic-and-technological-scann-2023-11-27-04-54-59-utc.mp4';
import { EnquireForm } from '../components/EnquireForm';

export const headers: HeadersFunction = () => ({});

export const meta: MetaFunction = () => {
  return [
    { title: 'Expressed Behaviour Analytics Platform' },
    {
      name: 'description',
      content: 'Welcome to the Expressed Behaviour Analytics Platform!',
    },
  ];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  return json('', {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function action(/*request*/) {
  return redirect('/home/');
}

export default function Home() {
  const resp = useLoaderData<typeof loader>();
  const [enquire, setEnquire] = useState<boolean>(false);
  const [activeUseCase, setActiveUseCase] = useState<
    'digital' | 'customerExp' | 'consumer'
  >('digital');
  const [billingType, setBillingType] = useState('yearly');

  const pricingRef = createRef<HTMLDivElement>();
  const topRef = createRef<HTMLDivElement>();

  useEffect(() => {
    document.body.style.overflow = enquire ? 'hidden' : '';

    return () => {
      document.body.style.overflow = '';
    };
  }, [enquire]);

  const toggleEnquire = () => {
    setEnquire(!enquire);
  };

  const enquireFormButtonActions = [
    {
      label: 'Send',
      onClick: () => {
        toggleEnquire(); // Close modal on confirm
      },
    },
  ];

  // Makes the pricing columns have equal height
  useEffect(() => {
    const setEqualHeight = () => {
      const pricingColumns = document.querySelectorAll('.pricingColumn');
      let maxHeight = 0;

      pricingColumns.forEach((col) => {
        col.style.height = 'auto'; // Reset height before measuring
        maxHeight = Math.max(maxHeight, col.offsetHeight);
      });

      pricingColumns.forEach((col) => {
        col.style.height = `${maxHeight}px`;
      });
    };

    setEqualHeight(); // Set height on initial load
    window.addEventListener('resize', setEqualHeight); // Adjust on window resize

    return () => window.removeEventListener('resize', setEqualHeight);
  }, []);

  function triggerSignup(): void {
    const signupButton =
      document.getElementById('signupButton');

    if (signupButton !== null)
      signupButton?.click();
    else
      window.location.href = '/uploads/projects';
  }

  return (
    <div>
      <Header pricingRef={pricingRef} />
      <BackgroundGradient />
      <div id="home" ref={topRef}>
        <div className="homeContent">
          <div id="titleContent">
            <h1 className="introHeading">Cumulus</h1>
            <p className="introSubheading" id="introP">
              {`Understand emotion.
                Measure impact.
                Optimise decisions.`}
            </p>
            <div id="introVideoWrapper">
              <video
                autoPlay
                loop
                muted // prevents certain browsers from blocking autoplay (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#attributes)
                className="introVideo"
                onError={(e) => {
                  e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                  e.currentTarget.src = deviceImage; // Fallback to the original JPG or PNG
                }}
              >
                <source src={introVideo} />
              </video>
            </div>
            <div className="intro-section columnArrangement">
              <p className="introSubheading">
                {`Actionable, objective, business insights about how
                your customers feel using your product, service or content.`}
              </p>
              <p className="introParagraph">
                By monitoring facial movements we highlight aspects of the
                interactions that cause changes in emotional response, providing
                unique insights so you can better understand customers' feelings
                and sentiment during the engagement.
              </p>
            </div>
            <div className="buttonContainer">
              <button className="introButton boldText" id="getStartedButton" onClick={triggerSignup}>
                Get started
              </button>
              <button
                className="introButton boldText"
                id="enquireButton"
                onClick={toggleEnquire}
              >
                Enquire
              </button>
            </div>
            {enquire && (
              <EnquireForm
                buttons={enquireFormButtonActions}
                onClose={toggleEnquire}
              />
            )}
          </div>

          <div className="interactiveDemoSection">
            <InteractiveDemo />
          </div>
          <div id="contentUsageSection">
            <ReactFlowProvider>
              <ArrowTimeline />
            </ReactFlowProvider>
          </div>
          <div id="carouselSection">
            <Carousel />
          </div>
          <div id="useCasesSection">
            <h3>Use Cases</h3>
            <div id="useCasesButtonsDiv">
              <button
                className={`useCaseButton boldText ${activeUseCase == 'digital' && 'active'}`}
                onClick={() => {
                  setActiveUseCase('digital');
                }}
              >
                Digital
              </button>
              <button
                className={`useCaseButton boldText ${activeUseCase == 'customerExp' && 'active'}`}
                onClick={() => {
                  setActiveUseCase('customerExp');
                }}
              >
                Customer Experience
              </button>
              <button
                className={`useCaseButton boldText ${activeUseCase == 'consumer' && 'active'}`}
                onClick={() => {
                  setActiveUseCase('consumer');
                }}
              >
                Consumer
              </button>
            </div>
            <div id="useCasesBody">
              <div id="imageDiv">
                <img
                  src={useCases.filter((p) => p.id == activeUseCase)[0].webp}
                  className="useCaseImage"
                  onError={(e) => {
                    e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                    e.currentTarget.src = useCases.filter(
                      (p) => p.id == activeUseCase
                    )[0].png; // Fallback to the original JPG or PNG
                  }}
                />
              </div>
              <p id="useCasesTextBody">
                {useCases.filter((p) => p.id == activeUseCase)[0].body}
              </p>
            </div>
          </div>
        </div>
        <div ref={pricingRef} id="pricingSection">
          <div className="toggle-container">
            <button
              className={`toggle-button boldText ${billingType === 'monthly' ? 'active' : ''}`}
              onClick={() => setBillingType('monthly')}
            >
              Monthly
            </button>
            <button
              className={`toggle-button boldText ${billingType === 'yearly' ? 'active' : ''}`}
              onClick={() => setBillingType('yearly')}
            >
              Yearly
            </button>
          </div>
          <div className="rowArrangement" id="pricingRow">
            {pricing.map((e, i) => {
              return (
                <div
                  className={`pricingColumn ${e.title == 'Enterprise' && 'enterprise'}`}
                  key={`pricing-${e.title}`}
                  style={{
                    backgroundColor: `${e.title == 'Trial' ? '#0B99E7' : e.title == 'Basic' ? 'var(--secondary-shade-2)' : e.title == 'Professional' ? 'var(--secondary-shade-1)' : 'var(--accent)'}`,
                  }}
                >
                  <div
                    className={`pricingHeader ${e.title == 'Enterprise' && 'enterprise'}`}
                    style={{
                      backgroundColor: `${e.title == 'Trial' ? 'var(--secondary-shade-2)' : e.title == 'Basic' ? 'var(--secondary-shade-1)' : e.title == 'Professional' ? 'var(--secondary)' : 'var(--accent)'}`,
                    }}
                  >
                    <text className="pricingTitle boldText">{e.title}</text>
                    <div className="price boldText">
                      {((billingType == 'yearly' && e.priceYearly) ||
                        (billingType == 'monthly' && e.priceMonthly)) && (
                          <text>
                            {billingType == 'yearly'
                              ? e.priceYearly
                              : e.priceMonthly}
                            {(e.title == 'Basic' ||
                              e.title == 'Professional') && (
                                <>
                                  <span className="subscript">/month</span>

                                  {billingType === 'yearly' ? (
                                    <div className="subprice">
                                      (£{e.yearlyValue * 12}/year)
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                          </text>
                        )}
                    </div>
                  </div>
                  <div className={'featureColumn'}>
                    <table className="featureTable boldText">
                      <tbody>
                        {e.feature.map((feature, i) => (
                          <tr
                            key={`${e.title}-${feature}`}
                            className={
                              i === e.feature.length - 1 ? 'noBottomBorder' : ''
                            }
                          >
                            <td className="featureCheckColumn">
                              <FaCircleCheck className="featureCheck" />
                            </td>
                            <td className="featureTextColumn">
                              <span>{feature}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <button
                    className={'pricingButton'}
                    onClick={() => {
                      if (e.title == 'Trial') {
                        const signupButton =
                          document.getElementById('signupButton');
                        signupButton?.click();
                      } else
                        window.location.href =
                          e.link + '&billing=' + billingType;
                    }}
                  >
                    {e.cta}
                  </button>
                </div>
              );
            })}
          </div>
        </div>

        <div className="missionSection">
          <p
            className="boldText"
            style={{ fontSize: '18px', color: 'var(--primary)' }}
          >
            BLUESKEYE AI are global leaders in using face data to understand
            expressed human behaviour. We have validated that our technology is
            better than the human eye for assessing changes in emotional
            responses.
          </p>
          <p style={{ fontSize: '18px' }}>
            In addition to commercial customers in the consumer, pharmaceutical
            and automotive spaces our technology is progressing through clinical
            trials to assure the UK health regulatory authorities of its
            efficacy.
          </p>
          <p style={{ fontSize: '18px' }}>
            BLUESKEYE AI uses continuous multidimensional approach to
            continuously measure people's emotion throughout their experience of
            using your product or consuming your content. This means we can
            measure transitions between emotional state throughout. This better
            fits with the real human experience and provides richer and more
            nuanced insights.
          </p>
          <p style={{ fontSize: '18px' }}>
            The technology has been in development for over 20 years, initially
            at the University of Nottingham and with Blueskeye since it was spun
            out of the University in 2019.
          </p>
          <p style={{ fontSize: '18px' }}>
            The technology has been trained using our data bank of over XX
            billion data items and been proven to work effectively irrespective
            of apparent age, gender and ethnicity.
          </p>
        </div>
        <button
          id="topOfPageButton"
          onClick={() => {
            topRef?.current?.scrollIntoView({ behavior: 'smooth' });
          }}
        >
          {`Return to top`}
          {<IoArrowUpOutline style={{ fontSize: '1.5vw' }} />}
        </button>
      </div>
      <div className="footerSection">
        <Footer />
      </div>
    </div>
  );
}
