import { useEffect, useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { sendEmailEnquiry } from '~/utils/ServerRequest';
import logo from '../assets/bskFace.png';
import '../styles/EnquireForm.css'; // Include CSS for styling

type FormInputs = {
  email: string;
  type: string;
  message: string;
};

type EnquiryType =
  | 'Enterprise Details'
  | 'General Enquiry'
  | 'Company Enquiry'
  | 'Other';

// Map frontend values to backend expected values
const enquiryTypeMap: Record<string, EnquiryType> = {
  enterprise: 'Enterprise Details',
  general: 'General Enquiry',
  company: 'Company Enquiry',
  other: 'Other',
};

export const EnquireForm = ({
  buttons,
  onClose,
}: {
  buttons?: any;
  onClose: () => void;
}) => {
  const formRows: {
    id: string;
    label: string;
    inputPlaceholder?: string;
  }[] = [
    { id: 'email', label: 'Your Email Address' },
    { id: 'type', label: 'Enquiry Type' },
    { id: 'message', label: 'Message' },
  ];

  const [formInputs, setFormInputs] = useState<FormInputs>({
    email: '',
    type: '',
    message: '',
  });
  const [formFilled, setFormFilled] = useState<null | Boolean>(null);
  const [visible, setVisible] = useState<Boolean>(true);
  const [email, setEmail] = useState<string>('');
  const [emailInvalid, setEmailInvalid] = useState<boolean | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const isEmailValid = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Function to send the enquiry to the backend
  const sendEnquiry = async (enquiryData: {
    email: string;
    enquiryType: EnquiryType;
    enquiry: string;
  }) => {
    try {
      setIsSubmitting(true);
      setSubmitError(null);

      const response = await sendEmailEnquiry(enquiryData);

      console.log(response);

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.data?.message || 'Failed to submit enquiry');
      }

      return data;
    } catch (error) {
      console.error('Error submitting enquiry:', error);
      setSubmitError(
        error instanceof Error ? error.message : 'An unexpected error occurred'
      );
      throw error;
    } finally {
      setIsSubmitting(false);
    }
  };

  async function handleFormSubmit(e: any) {
    e.preventDefault();

    let count = 0;
    formRows.forEach((row) => {
      var textarea = document.getElementsByName(row.id);
      if ((textarea[0] as HTMLInputElement).value === '') {
        count += 1;
      }
    });

    if (count === 0) {
      setFormFilled(true);
      if (isEmailValid(email)) {
        setEmailInvalid(false);

        try {
          // Map the form type to the expected backend value
          const enquiryType = enquiryTypeMap[formInputs.type];

          await sendEnquiry({
            email: formInputs.email,
            enquiryType: enquiryType,
            enquiry: formInputs.message,
          });

          // If successful, close the form
          handleCloseForm();
        } catch (error) {
          // Error handling is done in the sendEnquiry function
          // We just prevent form closure here
        }
      } else {
        setEmailInvalid(true);
      }
    } else {
      setFormFilled(false);
    }
  }

  const handleChange = (e: any) => {
    // Destructure the name and value from the element
    const { name, value } = e.target;
    setFormInputs({ ...formInputs, [name]: value });
  };

  const handleCloseForm = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!visible) {
      const timer = setTimeout(() => {
        onClose(); // Close only after fade-out animation finishes
      }, 1000); // This should match the duration of the fade-out animation (1s)

      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <div className={visible ? '' : 'fade-out'} id="enquireOverlay">
      <div id="enquireContent">
        <button id="enquireCloseButton" onClick={handleCloseForm}>
          <IoIosCloseCircleOutline style={{ fontSize: '3vw' }} />
        </button>
        <div className="rowArrangement" id="enquireHeaderRow">
          <img
            src={logo}
            width={'100vw'}
            id="popupLogo"
            alt="BLUESKEYE AI Logo"
          />
          <h2 id="enquireTitle">Enquire</h2>
        </div>
        <form id="enquireForm" onSubmit={handleFormSubmit}>
          {formRows.map((row) => {
            if (row.id === 'email') {
              return (
                <div className="enquireFormRow" key="emailRow">
                  <p>
                    {`${row.label}`} <span className="redText">*</span>
                  </p>
                  <textarea
                    className={`enquireFormInput`}
                    name={row.id}
                    value={email}
                    onChange={(e) => {
                      handleChange(e);
                      setEmail(e.target.value);
                    }}
                    placeholder={row.inputPlaceholder}
                  ></textarea>
                </div>
              );
            }
            if (row.id === 'type') {
              return (
                <div className="enquireFormRow" key="titleRow">
                  <p>
                    {`${row.label}`} <span className="redText">*</span>
                  </p>
                  <select
                    name="type"
                    className="enquireFormInput"
                    onChange={handleChange}
                    required
                    defaultValue={''}
                  >
                    <option value="" disabled>
                      Select an enquiry type
                    </option>
                    <option value="enterprise">Enterprise Details</option>
                    <option value="general">General Enquiry</option>
                    <option value="company">Company Enquiry</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              );
            }
            return (
              <div className="enquireFormRow" key={`${row.id}Row`}>
                <p>
                  {row.label} <span className="redText">*</span>
                </p>
                <textarea
                  className={`enquireFormInput ${row.id === 'message' && 'messageTextArea'}`}
                  name={row.id}
                  onChange={handleChange}
                  placeholder={row.inputPlaceholder}
                ></textarea>
              </div>
            );
          })}
        </form>
        {formFilled === false && (
          <p className="redText" id="filInText">
            Please fill in the required field(s).
          </p>
        )}
        {emailInvalid && (
          <p className="redText" id="filInText">
            Please enter a valid email address.
          </p>
        )}
        {submitError && (
          <p className="redText" id="filInText">
            {submitError}
          </p>
        )}
        <div className="button-row">
          {buttons.map((button: any, index: number) => {
            if (button.label === 'Send')
              return (
                <button
                  key={index}
                  onClick={(e) => {
                    handleFormSubmit(e);
                  }}
                  disabled={button.disabled || isSubmitting}
                  className={`enquireFormButton ${(button.disabled || isSubmitting) && `disabledButton`}`}
                >
                  {isSubmitting ? 'Sending...' : button.label}
                </button>
              );
          })}
        </div>
      </div>
      <div id="enquireBackdrop" onClick={handleCloseForm}></div>
    </div>
  );
};
