// InteractiveDemo.tsx
import { useCallback, useState } from 'react';
import { GoHorizontalRule } from 'react-icons/go';
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import {
  Export1_png,
  Export1_webp,
  Export2_png,
  Export2_webp,
  ProjectDashboard_png,
  ProjectDashboard_webp,
  Upload1_png,
  Upload1_webp,
  Upload2_png,
  Upload2_webp,
  Upload3_png,
  Upload3_webp,
  Upload4_png,
  Upload4_webp,
  Upload5_png,
  Upload5_webp,
  Upload6_png,
  Upload6_webp,
  VideoAnalysis1_png,
  VideoAnalysis1_webp,
  VideoAnalysis2_png,
  VideoAnalysis2_webp,
  VideoAnalysis3_png,
  VideoAnalysis3_webp,
  VideoAnalysis4_png,
  VideoAnalysis4_webp,
  VideoAnalysis5_png,
  VideoAnalysis5_webp,
} from '../assets/landing/interactiveDemo';
import '../styles/InteractiveDemo.css';

export const InteractiveDemo = () => {
  const items = [
    { id: 1, title: 'Export1', image: Export1_png, webp: Export1_webp },
    { id: 2, title: 'Export2', image: Export2_png, webp: Export2_webp },
    {
      id: 3,
      title: 'Project Dashboard',
      image: ProjectDashboard_png,
      webp: ProjectDashboard_webp,
    },
    { id: 4, title: 'Upload1', image: Upload1_png, webp: Upload1_webp },
    { id: 5, title: 'Upload2', image: Upload2_png, webp: Upload2_webp },
    { id: 6, title: 'Upload3', image: Upload3_png, webp: Upload3_webp },
    { id: 7, title: 'Upload4', image: Upload4_png, webp: Upload4_webp },
    { id: 8, title: 'Upload5', image: Upload5_png, webp: Upload5_webp },
    { id: 9, title: 'Upload6', image: Upload6_png, webp: Upload6_webp },
    {
      id: 10,
      title: 'Video Analysis 1',
      image: VideoAnalysis1_png,
      webp: VideoAnalysis1_webp,
    },
    {
      id: 11,
      title: 'Video Analysis 2',
      image: VideoAnalysis2_png,
      webp: VideoAnalysis2_webp,
    },
    {
      id: 12,
      title: 'Video Analysis 3',
      image: VideoAnalysis3_png,
      webp: VideoAnalysis3_webp,
    },
    {
      id: 13,
      title: 'Video Analysis 4',
      image: VideoAnalysis4_png,
      webp: VideoAnalysis4_webp,
    },
    {
      id: 14,
      title: 'Video Analysis 5',
      image: VideoAnalysis5_png,
      webp: VideoAnalysis5_webp,
    },
    // Add more items as needed
  ];
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  return (
    <div>
      <div className="alignItemsCenter" id="demoPagination">
        {items.map((item, index) => {
          return (
            <GoHorizontalRule
              key={`${index}-rule`}
              className={`${currentSlide == index && 'currentSlide'}`}
              style={{
                height: '5vh',
                fontSize: '5vw',
                color: `${currentSlide == index ? 'var(--secondary-shade-2)' : 'var(--primary-shade)'}`,
              }}
            />
          );
        })}
        <HiOutlineChevronLeft
          style={{ fontSize: '2vw' }}
          onClick={handlePrevious}
        />
        <HiOutlineChevronRight
          style={{ fontSize: '2vw' }}
          onClick={handleNext}
        />
      </div>
      <Swiper
        onSwiper={setSwiperRef}
        slidesPerView={1}
        centeredSlides={true} // Center slides
        id="demoSwiper"
        style={{ overflow: 'hidden' }} // Full width and hide overflow
        onSlideChange={(slide) => {
          setCurrentSlide(slide.activeIndex);
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <ClickableCard
              title={item.title}
              image={item.image}
              webp={item.webp}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const ClickableCard = ({
  title,
  image,
  webp,
}: {
  title: string;
  image: string;
  webp: string;
}) => {
  const handleClick = () => {
    // Add your click handling logic here (e.g., navigate to a page)
    //console.log("Card clicked: " + title);
  };

  return (
    <div className="demoSlide" onClick={handleClick}>
      <img
        src={webp}
        alt={title}
        className="demoSlideImage"
        onError={(e) => {
          e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
          e.currentTarget.src = image; // Fallback to the original JPG or PNG
        }}
      />

      <div className="demoSlideFooter">
        <span className="demoSlideTitle">{title}</span>
      </div>
    </div>
  );
};
