import {
  Handle,
  MarkerType,
  Position,
  ReactFlow,
  useReactFlow,
} from '@xyflow/react';
import { useEffect } from 'react';

export const ArrowTimeline = () => {
  const { fitView } = useReactFlow();

  useEffect(() => {
    fitView({ duration: 500 }); // Smooth scaling effect
  }, [fitView]);

  /* ---------------------------- Styling Functions --------------------------- */
  const getBorderColor = (value: number) => {
    const colors = [
      'var(--secondary)',
      'var(--secondary-shade-1)',
      'var(--secondary-shade-2)',
      '#038FD0',
    ];
    return colors[value - 1] || 'gray';
  };

  const createCircleNode = (
    id: string,
    x: number,
    y: number,
    value: number
  ) => ({
    id,
    data: { value },
    position: { x, y }, // Relative positioning
    type: 'circle',
  });

  const createTextNode = (id: string, value: string, x: number, y: number) => ({
    id,
    data: { value },
    position: { x, y }, // Relative positioning
    type: 'text',
  });

  const createEdge = (source: string, target: string, color: string) => ({
    id: `${source}-${target}`,
    source,
    target,
    type: 'smoothstep',
    markerEnd: { type: MarkerType.ArrowClosed, width: 10, height: 10, color },
    style: { strokeWidth: 5, stroke: color },
  });

  /* --------------------------- Relative Node Positions -------------------------- */
  const nodes = [
    createCircleNode('1', 100, 50, 1),
    createTextNode('1-text', 'Create a project', 30, 100), // Centered below

    createCircleNode('2', 350, 120, 2),
    createTextNode('2-text', 'Upload a video', 280, 170), // Centered below

    createCircleNode('3', 600, 190, 3),
    createTextNode('3-text', 'View your emotional analysis outputs', 520, 240), // Centered below

    createCircleNode('4', 850, 260, 4),
    createTextNode(
      '4-text',
      'Export your data to help make data-driven decisions',
      780,
      310
    ), // Centered below
  ];

  const edges = [
    createEdge('1', '2', 'var(--secondary)'),
    createEdge('2', '3', 'var(--secondary-shade-1)'),
    createEdge('3', '4', 'var(--secondary-shade-2)'),
  ];

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div style={{ height: '400px', width: '100%' }}>
        {' '}
        {/* Flexible size */}
        <ReactFlow
          className="reactFlow"
          nodes={nodes}
          edges={edges}
          nodeTypes={{
            circle: ({ data }: { data: any }) => (
              <div className="circle-node">
                <Handle
                  type="target"
                  position={Position.Left}
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                />
                <div
                  className="circle"
                  style={{ border: `5px solid ${getBorderColor(data.value)}` }}
                >
                  <p className="circle-node-text-container">{data.value}</p>
                </div>
                <Handle
                  type="source"
                  position={Position.Right}
                  id="a"
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                />
              </div>
            ),
            text: ({ data }: { data: any }) => (
              <div className="text-node">
                <Handle
                  type="source"
                  position={Position.Top}
                  id="a"
                  style={{ backgroundColor: 'transparent', border: 'none' }}
                />
                <div className="text-node-text-container">
                  <p>{data.value}</p>
                </div>
              </div>
            ),
          }}
          fitView
          proOptions={{ hideAttribution: true }}
          elementsSelectable={false}
          nodesDraggable={false}
          panOnDrag={false}
          minZoom={1}
          maxZoom={1}
          preventScrolling={false}
        />
      </div>
    </div>
  );
};
