import { useEffect } from 'react';

export function BackgroundGradient() {
  useEffect(() => {
    const homeBackground = document.querySelector(
      '.homeBackground'
    ) as HTMLElement;

    if (homeBackground) {
      homeBackground.style.position = 'fixed';
      homeBackground.style.top = '0';
      homeBackground.style.left = '0';
      homeBackground.style.width = '100vw';
      homeBackground.style.height = '100vh';
      homeBackground.style.zIndex = '-1';
      homeBackground.style.backgroundSize = 'cover';
      homeBackground.style.backgroundAttachment = 'fixed';
    }

    const lerp = (start: number, end: number, t: number) =>
      start + t * (end - start);

    const changeGradient = () => {
      const maxScroll =
        document.documentElement.scrollHeight - window.innerHeight;
      let scrollFactor = window.scrollY / maxScroll;

      scrollFactor = Math.pow(scrollFactor, 0.7);

      const angle = lerp(135, 405, scrollFactor);
      const yOffset = lerp(0, 100, scrollFactor);

      if (homeBackground) {
        homeBackground.style.background = `linear-gradient(${angle}deg, #4FC3F7, #F8FAFC)`;
        homeBackground.style.backgroundPosition = `center ${yOffset}%`;
      }
    };

    // Call changeGradient immediately to set the initial background
    changeGradient();

    window.addEventListener('scroll', changeGradient);
    return () => window.removeEventListener('scroll', changeGradient);
  }, []);

  return <div className="homeBackground"></div>;
}
